<template>
    <div style="width: 100vw;height: 100vh;background-color: #F5F5F5;">
        <Header :title="'成长轨迹'" :bgColor="'#ffffff'" :leftClick="goBack"></Header>
        <div>
            <div v-for="(record, index) in dataList" :key="index" class="record-item">
                <p style="color: #333333;font-size: 12px;">{{ record.date_label }}</p>
                <p style="color: #7f7f7f;font-size: 12px;margin-top: 10px;">{{ record.song_name }}</p>
                <p style="color: #333333;font-size: 14px;margin-top: 12px;">学会了<span style="font-weight: bold;">{{
                    record.song_name_abbrev }}</span></p>
            </div>
        </div>
    </div>
</template>
<script>
import {
    getMyQuizRecordsV1
} from '../../api/app'
import {
    getAppKidId,
    getAppUserId
} from '../../utils/store'

import Header from '../../components/app/Header.vue'

export default ({
    name: 'index',
    components: {
        Header
    },
    data() {
        return {
            dataList: []
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getMyQuizRecordsV1(getAppUserId(), getAppKidId()).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.dataList = result.data
                }
            })
        },
        goBack() {
            this.$router.go(-1)
        }
    }
})
</script>


<style lang="less" scoped>
.record-item {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #EEEEEE;
}
</style>